import React from "react";
import Masonry from "react-masonry-component";
import PropTypes from "prop-types";
import tw from "twin.macro";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import HeaderType from "@utility/Header";
import CardPerspective from "@utility/CardPerspective";
import Components from "@builder/ComponentsPerspectiveLanding";
import HeaderContainer from "@utility/HeaderContainer";
import AnimateHeroText from "@utility/AnimateHeroText";
import TextArea from "@utility/TextArea";
import SearchEngines from "@utility/Seo";
import DynamicGlyphs from "@utility/glyphs/DynamicGlyphs";
import { screen } from "@helpers/media";

const MasonryHolder = styled.div`
  ${tw`pb-12 md:pb-16`}

  @media ${screen.md} {
    margin-right: -0.75rem;
    margin-left: -0.75rem;
  }

  @media ${screen.lg} {
    margin-right: -10px;
    margin-left: -10px
  }

  @media ${screen.xl} {
    margin-right: -25px;
    margin-left: -25px;
  }
`;

const PerspectiveLandingTemplate = ({
  blok: { seo, title, mobile_title, hero_image, hero_glyphs, body },
  pageContext
}) => {
  // Use useStaticQuery is required here because this is technically a component within
  // the page storyblokEntry
  const data = useStaticQuery(
    graphql`
      query {
        allStoryblokEntry(
          sort: { field_date_string: DESC }
          filter: {
            field_component: { eq: "perspective_post" }
            published_at: { ne: null }
          }
        ) {
          edges {
            node {
              full_slug
              content
            }
          }
        }
      }
    `
  );

  return (
    <div>
      <SearchEngines
        title={seo.title || pageContext.story.name || ""}
        description={seo.description || title || ""}
        image={seo.og_image || hero_image || ""}
      />
      <HeaderContainer>
        <div className="grid-wrapper">
          <div className="grid-row relative">
            <div className="col-12">
              <AnimateHeroText type="large">
                <HeaderType>
                  <TextArea
                    text={title}
                    mobile_text={mobile_title}
                    nestedAnimation
                  />
                </HeaderType>
              </AnimateHeroText>
            </div>
            {hero_glyphs && <DynamicGlyphs isHero glyphs={hero_glyphs} />}
          </div>
        </div>
      </HeaderContainer>
      <div tw="grid-wrapper">
        <div tw="grid-row">
          <div tw="col-12 xl:col-offset-1 xl:col-10">
            <MasonryHolder>
              <Masonry tw="justify-around">
                {data.allStoryblokEntry.edges.map(
                  ({ node, node: { content } }, index) => {
                    const contentObj = JSON.parse(content);
                    return (
                      <CardPerspective
                        multipleRows
                        key={`storyblok-link${index}`}
                        author={contentObj.author.name}
                        date={contentObj.date}
                        category={contentObj.categories}
                        title={contentObj.title}
                        url={`/${node.full_slug}`}
                        image={
                          contentObj.thumbnail_image
                            ? contentObj.thumbnail_image
                            : contentObj.hero_image
                        }
                      />
                    );
                  }
                )}
              </Masonry>
            </MasonryHolder>
          </div>
        </div>
      </div>
      <div>
        {body &&
          body.map(element =>
            React.createElement(Components(element.component), {
              key: element._uid,
              blok: element
            })
          )}
      </div>
    </div>
  );
};

PerspectiveLandingTemplate.propTypes = {
  blok: PropTypes.shape({
    seo: PropTypes.object,
    title: PropTypes.string,
    mobile_title: PropTypes.string,
    hero_image: PropTypes.string,
    hero_glyphs: PropTypes.array,
    component: PropTypes.string,
    body: PropTypes.array
  }).isRequired,
  seo: PropTypes.objectOf(PropTypes.string),
  pageContext: PropTypes.shape({
    story: PropTypes.object
  })
};

PerspectiveLandingTemplate.defaultProps = {
  seo: {},
  pageContext: {}
};

export default PerspectiveLandingTemplate;
